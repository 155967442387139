<template>
  <div>
    <!-- Start card wrapper -->
    <v-card class="SearchWrapper">
      <v-layout width="100%" row :algin-start="mdUp" :align-center="!mdUp">
        <!-- Start search title -->
        <v-flex xs12 sm3 style="margin-right: 1%">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-text-field
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            v-model="sSearch"
            append-icon="mdi-magnify"
            :placeholder="'Búsqueda general...'"
          >
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm3>
          <p class="FieldLabel"></p>

          <v-select
            v-model="aProject"
            :items="itemsProject"
            :key="itemsProject.sProjectId"
            class="custom-placeholder"
            item-text="sName"
            item-value="sProjectId"
            placeholder="Selecciona los proyectos"
            dense
            outlined
            multiple
            @change="filterProject()"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip style="height: 25px" v-if="index === 0">
                <span>{{ item.sName }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ aProject.length - 1 }} otros)
              </span>
            </template>
          </v-select>
          
 


        </v-flex>
        <v-flex class="text-start" xs12 sm3>
          <p class="FieldLabel"></p>
            <v-checkbox
            style="
              margin-top:3px;
              margin-left:20px;
              font-family: 'Poppins', sans-serif !important;
              text-align: left;
              font-weight: bold;
              font-family: 'Poppins', sans-serif;
              font-size: 5px !important;
              color: #000000;"
              v-model="bHasDebt"
              class="checkbox-input"
              label="Proveedores con adeudo"
              color="#ffce00"
              hide-details
            ></v-checkbox>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm2 class="text-right">
          <p class="FieldLabel"></p>
          <v-btn
            color="primary"
            style="
              color: black;
              box-shadow: 0px 20px 40px #00000029;
              border-radius: 8px;
              margin-right: -25px;
              height: 40px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              
            "
            @click="dialog = true"
          >
            Nuevo proveedor
          </v-btn>
        </v-flex>
        <!-- End search text field -->
      </v-layout>
    </v-card>

    <v-dialog
      v-model="dialog"
      :max-width="mdUp ? '30%' : '90%'"
      style="z-index: 9999"
    >
      <v-card
        class="rounded-xl"
        style="padding-bottom: 15px; padding-top: 15px"
      >
        <v-icon
          @click="dialog = false"
          color="#000000"
          style="position: absolute; padding: 5px; right: 20px"
          >mdi-close
        </v-icon>
        <v-card-title
          class="headline justify-center"
          style="text-align: center"
        >
          Nuevo proveedor
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newSupplier.sFullName"
                  clearable
                  class="AddProviderTextField"
                  placeholer="Nombre del proveedor"
                  label="Nombre del proveedor"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newSupplier.sCompanyName"
                  clearable
                  dense
                  class="AddProviderTextField"
                  placeholer="Nombre de la empresa"
                  label="Nombre de la empresa"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newSupplier.sRFC"
                  clearable
                  dense
                  class="AddProviderTextField"
                  placeholer="RFC"
                  label="RFC"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newSupplier.sEmail"
                  clearable
                  dense
                  class="AddProviderTextField"
                  placeholer="Correo electrónico"
                  label="Correo electrónico"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newSupplier.sCompanyEconomicActivity"
                  clearable
                  dense
                  class="AddProviderTextField"
                  placeholer="Rubro"
                  label="Rubro"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-layout justify-center>
          <v-card-actions justify-center>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6" pa-3>
                  <v-btn
                    @click="dialog = false"
                    x-large
                    color="black"
                    width="175"
                    outlined
                    style="
                      height: 40px;
                      font-size: 13px;
                      font-weight: 600;
                      border-radius: 5px;
                    "
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" pa-3>
                  <v-btn
                    :loading="bLoading"
                    @click="addSupplier"
                    x-large
                    width="175"
                    color="primary"
                    style="
                      height: 40px;
                      color: black;
                      box-shadow: 0px 20px 40px #00000029;
                      border-radius: 5px;
                      font-size: 13px;
                      font-weight: 600;
                    "
                    >Crear
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- End card wrapper -->
  </div>
</template>
<script>
export default {
  data: () => ({
    newSupplier: {
      sFullName: "",
      sEmail: "",
      sCompanyName: "",
      sRFC: "",
      sCompanyEconomicActivity: "",
    },
    dialog: false,
    areaChosen: null,
    statusChosen: null,
    // store search input
    sSearch: "",
    bMenuFinal: false,
    bMenuInitial: false,
    dateStart: null,
    dateEnd: null,
    bLoading: false,
    itemsProject: [],
    aProject: [],
    itemsSupplier: [],
    aSupplier: [],
    bHasDebt: false
  }),
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setStatus", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    this.$store.commit("setProject", null);
    //  this.$store.commit("setDebtDays", null);
  },
  mounted() {
    this.getItemsProject();
    this.getItemsSupplier();
  },
  methods: {
    addSupplier() {
      this.bLoading = true;
      var body = encode.encodeJSON(this.newSupplier);
      db.post(`${uri}/api/v1/supplier`, body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.bLoading = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
          this.dialog = false;
          this.newSupplier = {
            sFullName: "",
            sEmail: "",
            sCompanyName: "",
            sRFC: "",
            sCompanyEconomicActivity: "",
          };
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get the project from api
    getItemsProject() {
      // get Array Providers
      db.get(`${uri}/api/v1/projects/debts`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          sSupplierId: '',
        },
      })
        .then((resp) => {
          this.itemsProject = resp.data.projects;
          for (let i = 0; i < this.itemsProject.length; i++) {
            const element = this.itemsProject[i];
          }

        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    },
    filterProject() {
      this.$store.commit("setProject", this.aProject);
    },
    getItemsSupplier() {
      // get Array Project
      db.get(`${uri}/api/v1/projects/debts`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        // params: {
        //   iPageNumber: 1,
        //   bHasDebt: true
        // },
      })
        .then((resp) => {
          this.itemsSupplier = resp.data.projects;

          for (let i = 0; i < this.itemsSupplier.length; i++) {
            const element = this.itemsSupplier[i];
            // this.aProvider.push(element.sSupplierId);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    },
    filterSupplier() {
      this.$store.commit("setSupplier", this.aSupplier);
    },
  },
  watch: {
    statusChosen: function () {
      this.$store.commit("setStatus", this.statusChosen);
    },
    areaChosen: function () {
      this.$store.commit("setArea", this.areaChosen);
    },
    dateStart: function () {
      this.dateEnd = null;
      this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 800),
    bHasDebt() {
      this.$store.commit("setHasDebt", this.bHasDebt);
    },
    // aSupplier() {
    //   this.$store.commit("setSupplier", this.aSupplier);
    // },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style>
.AddProviderTextField ::placeholder {
  font-size: 12px !important;
  text-transform: uppercase;
}
.AddProviderTextField {
  margin-top: -6px;
}

.FieldLabel {
  text-align: left;
  font: normal normal normal 12px/14px "Poppins", sans-serif;
  margin-bottom: 0px !important;
  margin-left: 20px;
  z-index: 100;
  color: #008b83;
  opacity: 1;
}
.custom-placeholder input::placeholder{
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  color: #000000;
}
.custom-placeholder input::-moz-placeholder {
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  color: #000000;
}
</style>
<style
  scoped>
.v-select__slot,
.v-text-field .v-label .checkbox-input {
  font-family: "Poppins", sans-serif !important;
}

.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}
</style>